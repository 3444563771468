$(function() {

    var map;

    const user_type_dropdown = document.getElementById('user_user_type_id');
    const client_primary_div = document.getElementById('client_primary');
    const client_dropdown_div = document.getElementById('client_dropdown');
    const client_dropdown = document.getElementById('user_user_client_attributes_client_id');
    if (user_type_dropdown) {
        user_type_dropdown.addEventListener('change', function () {
            if (this.options[this.selectedIndex].text === 'Client') {
                client_primary_div.classList.remove('display_none');
                client_dropdown_div.classList.remove('display_none');
                client_dropdown.required = true;
            } else {
                client_primary_div.classList.add('display_none');
                client_dropdown_div.classList.add('display_none');
                client_dropdown.required = false;
            }

            if (this.options[this.selectedIndex].text === 'Installer') {
                $('.installer_field').show();
                document.querySelectorAll('.installer_field *').forEach(function(node) {
                    node.required = true;
                });
            } else {
                $('.installer_field').hide();
                document.querySelectorAll('.installer_field *').forEach(function(node) {
                    node.required = false;
                });
            }
        });
    }

    $('.start_datepicker').datepicker({
        dateFormat: 'MM dd, yy',
        changeMonth: true,
        changeYear: true,
        showAnim: 'slideDown',
        yearRange: '-2:+2',
        altField: '#start_date_hidden',
        altFormat: 'yy-mm-dd'
    });

    $('.end_datepicker').datepicker({
        dateFormat: 'MM dd, yy',
        changeMonth: true,
        changeYear: true,
        showAnim: 'slideDown',
        yearRange: '-2:+2',
        altField: '#end_date_hidden',
        altFormat: 'yy-mm-dd'
    });

    $('.event_datepicker').datepicker({
        dateFormat: 'MM dd, yy',
        changeMonth: true,
        changeYear: true,
        showAnim: 'slideDown',
        yearRange: '-2:+2',
        altField: '#event_date_hidden',
        altFormat: 'yy-mm-dd'
    });

    $('#user_table').DataTable({
        "aoColumnDefs": [
            { "bSortable": false, "aTargets": [3, 4, 5, 6] },
            { "bSearchable": false, "aTargets": [4, 5, 6] }
        ],
        bSortCellsTop: true
    });

    $('#client_table').DataTable({
        "aoColumnDefs": [
            { "bSortable": false, "aTargets": [1, 2, 3, 6, 7, 8] },
            { "bSearchable": false, "aTargets": [8] }
        ],
        bSortCellsTop: true
    });

    $('.check_unarchive').click(function(){
       alert('Select campaign to view image');
    });

    $('.filter').change(function() {
        var filter_vars = '?page=1';
        const filters = $('.filter');
        filters.each(function() {
            const filter_col = $(this).data('filter_param');
            const filter_val = $(this).val();
            if(filter_val) {
                filter_vars += '&' + filter_col + '=' + filter_val;
            }
        });
        window.location = window.location.pathname + filter_vars;
    });

    $('.tab').click(function() {
        $('.tab').removeClass('highlight');
        $(this).addClass('highlight');
        $('.installation-image').hide();
        var filters = $(this).data('tab').split(' ');
        $.each(filters, function(index, value) {
            $('div.' + value).show();
        });
    });

    $('.confirm_reject').on('click', function(e) {
        e.preventDefault();
        const confirm_reject = $(this);

        const image_div = confirm_reject.closest('.installation-image');
        const reason_div = confirm_reject.siblings('.vote_reason');
        const image = confirm_reject.siblings('.image_link').children('img');
        const vote_class = confirm_reject.hasClass('confirm') ? 'image_confirm' : 'image_reject';
        //Do not do anything if image was already reviewed
        // if(!image_div.hasClass('confirmed') && !image_div.hasClass('rejected')) {
            //If the user clicks the red X then show them the reason text box, otherwise continue on
            if(!confirm_reject.hasClass('reject')) {
                if(!image_div.hasClass('rejected')) {
                    //Submit text field and set image status to Client Viewable
                    const image_id = $(this).parent('.img_main').data('image_id');
                    const campaign_id = $(this).parent('.img_main').data('campaign_id');
                    const open_count = $('#open_img_count');
                    const confirm_count = $('#confirm_img_count');
                    const header_confirm_count = $('#header_confirm_img_count');
                    const reject_count = $('#reject_img_count');
                    const client_viewable_count = $('#client_viewable_img_count');
                    const confirm = (image_div.hasClass('image_confirm') || image_div.hasClass('reason_not_needed')) ? 1 : 0;
                    const reason_not_needed = image_div.hasClass('reason_not_needed') ? 1 : 0;
                    const reason_p = reason_div.siblings('.vote_reason_saved');
                    const vote_reason = confirm ? '' : reason_div.children('input').val();

                    const data = {
                        campaign_id: campaign_id,
                        confirm: confirm,
                        client_viewable: 0,
                        reason: vote_reason
                    };

                    $.ajax({data: data, type: 'PUT', url: '/installation_images/' + image_id})
                        .done(function () {
                            //Update div class/count
                            $(image_div).removeClass('open');
                            $(image_div).removeClass(vote_class);
                            reason_div.children('input').removeClass(vote_class);
                            reason_div.find('svg').removeClass(vote_class);
                            open_count.text(parseInt(open_count.text()) - 1);
                            if(reason_not_needed) {
                                confirm_count.text(parseInt(confirm_count.text()) + 1);
                                header_confirm_count.text(parseInt(header_confirm_count.text()) + 1);
                                $(image_div).removeClass('reason_not_needed');
                                $(image_div).addClass('confirmed');
                                updateCircleColorWithStatusChange(image_id, "confirmed")
                                //client_viewable_count.text(parseInt(client_viewable_count.text()) + 1);
                            }else if(confirm) {
                                //count is incremented when going from empty check to filled check. Don't need to increment
                                // when going from filled check to blue eyeball.
                                //confirm_count.text(parseInt(confirm_count.text()) + 1);
                                $(image_div).addClass('client_viewable');
                                client_viewable_count.text(parseInt(client_viewable_count.text()) + 1);
                            } else {
                                $(image_div.addClass('rejected'));
                                reject_count.text(parseInt(reject_count.text()) + 1);
                            }

                            //Remove highlights from other images, add to this one
                            $('.installation-image').removeClass('highlight_image');
                            $(image_div).addClass('highlight_image');

                            reason_p.text(vote_reason); //display rejection reason
                            $('.image_link img').css('opacity', 1); //restore image opacity
                            reason_div.hide(); //hide rejection input
                            if($('.tab.highlight').data('tab').includes('open')) { //Hide image if tab is 'open'
                                image_div.fadeOut(1500);
                            }
                        });
                } else {
                    undo_logic(this);
                }
            } else {
                if(!image_div.hasClass('rejected')) {
                    //Update div class for hover buttons
                    $(image_div).addClass('image_reject');
                    //Remove highlights from other images, add to this one
                    $('.installation-image').removeClass('highlight_image');
                    $(image_div).addClass('highlight_image');

                    //Show the reason input and give it focus, add class for colors
                    reason_div.show();
                    reason_div.children('input').addClass('image_reject').focus();
                    reason_div.find('svg').addClass('image_reject');
                    //Keep image slightly opaque until reason is entered
                    image.css('opacity', 0.3);
                } else {
                    undo_logic(this);
                }
            }
        // }
    });

    // 4/16/2020
    // Accept/Reject Reason is no longer required so it doesn't make sense to shake and highlight the field
    //$('.vote_reason').focusout(function() {
    //    const reason_div = $(this).closest('div');
    //    const vote_reason = reason_div.children('input');
    //    if(vote_reason.val().trim().length === 0) {
    //        vote_reason.effect('shake');
    //        vote_reason.animate({backgroundColor: 'yellow'}, 500);
    //    }
    //});

    $('.vote_reason_submit svg').on('click', function(e) {
        e.preventDefault(); //necessary to stop page from scrolling to top
        const reason_div = $(this).closest('div');
        const image_id = reason_div.parent('.img_main').data('image_id');
        const campaign_id = reason_div.parent('.img_main').data('campaign_id');
        const reason_p = reason_div.siblings('.vote_reason_saved');
        const vote_reason = reason_div.children('input').val();
        const image_div = $('#image_' + image_id);
        const open_count = $('#open_img_count');
        const confirm_count = $('#confirm_img_count');
        const header_confirm_count = $('#header_confirm_img_count');
        const reject_count = $('#reject_img_count');
        const confirm = $(this).hasClass('image_confirm') ? 1 : 0;
        const image_class = confirm ? 'confirmed' : 'rejected';

        const data = {
            campaign_id: campaign_id,
            confirm: confirm,
            client_viewable: 0,
            reason: vote_reason
        };

        // 4/16/2020
        // Accept/Reject Reason is no longer required so it doesn't make sense to shake and highlight the field
        //if(vote_reason.trim().length > 0) {
        //Update status/reason
        $.ajax({data: data, type: 'PUT', url: '/installation_images/' + image_id})
            .done(function () {
                // Update div class
                $(image_div).removeClass('open');
                if (confirm) {
                    $(image_div).removeClass('image_confirm');
                    reason_div.children('input').removeClass('image_confirm');
                    reason_div.find('svg').removeClass('image_confirm');
                    confirm_count.text(parseInt(confirm_count.text()) + 1);
                    header_confirm_count.text(parseInt(header_confirm_count.text()) + 1);
                } else {
                    $(image_div).removeClass('image_reject');
                    reason_div.children('input').removeClass('image_reject');
                    reason_div.find('svg').removeClass('image_reject');
                    reason_p.text(vote_reason); //display rejection reason
                    reject_count.text(parseInt(reject_count.text()) + 1);
                    updateCircleColorWithStatusChange(image_id, "rejected")
                }
                open_count.text(parseInt(open_count.text()) - 1);
                $(image_div).addClass(image_class);
                //reason_p.text(vote_reason); //display rejection reason
                $('.image_link img').css('opacity', 1); //restore image opacity
                reason_div.hide(); //hide rejection input
                if($('.tab.highlight').data('tab').includes('open')) { //Hide image if tab is 'open'
                    image_div.fadeOut(1500);
                }
            });
        //}
    })

    $('.go_back').on('click', function(e) {
        history.go(-1);
        return false;
    });

    //  This is specifically for google chrome so that back button takes you back to the highlighted tab
    $('.image_link').on('click', function(e) {
        sessionStorage.setItem("scrollPos", $(window).scrollTop());

        var filters = $('.highlight').data('tab').split(' ');
        $.each(filters, function(index, value) {
            if(index == 0) {
                sessionStorage.setItem("picturesfilter", value);
            }
        });

        var installation_image_id = $(this).data('installation-image-id');
        var campaign_id = $(this).data('campaign-id');
        var installer = $(this).data('installer');
        var status_filter = filters;
        var image_page_url = '/installation_images/' + installation_image_id + '?campaign_id=' + campaign_id;
        if(installer) {
            image_page_url += '&installer=' + installer;
        }
        if(status_filter) {
            $.each(filters, function(index, value) {
                image_page_url += '&status_filter[]=' + value;
            });
        }

        window.location.href = image_page_url;
        return false;
    });

    $('.vote_reason_input').keypress(function(e) { //save vote reason on enter
        if(e.keyCode === 13) {
            $(this).siblings('.vote_reason_submit').children('svg').trigger('click');
        }
    });

    $('.client_show').on('click', function(e) {
        e.preventDefault();
        const image_id = $(this).parent('.img_main').data('image_id');
        const campaign_id = $(this).parent('.img_main').data('campaign_id');
        const image_div = $('#image_' + image_id);
        const client_viewable_count = $('#client_viewable_img_count');
        const reason_div = $(this).siblings('.vote_reason');
        const vote_reason = reason_div.children('input').val();

        const data = {
            campaign_id: campaign_id,
            confirm: 1,
            client_viewable: 1,
            reason: vote_reason
        };

        $.ajax({data: data, type: 'PUT', url: '/installation_images/' + image_id})
          .done(function () {
              //Update div class/count
              $(image_div).removeClass('confirmed');
              client_viewable_count.text(parseInt(client_viewable_count.text()) + 1);
              $(image_div).addClass('client_viewable');
              updateCircleColorWithStatusChange(image_id, "client_viewable")

              //Remove highlights from other images, add to this one
              $('.installation-image').removeClass('highlight_image');
              $(image_div).addClass('highlight_image');
          });
    });
    $('.blue_eye_done').on('click', function(e) {
        e.preventDefault();
        undo_logic(this);
    });

    function undo_logic(icon_clicked) {
        const image_id = $(icon_clicked).parent('.img_main').data('image_id');
        const campaign_id = $(icon_clicked).parent('.img_main').data('campaign_id');
        const image_div = $('#image_' + image_id);
        const reject_p = $(icon_clicked).siblings('p');
        const open_count = $('#open_img_count');
        const confirm_count = $('#confirm_img_count');
        const header_confirm_count = $('#header_confirm_img_count');
        const reject_count = $('#reject_img_count');
        const client_viewable_count = $('#client_viewable_img_count');

        // if(confirm('Re-open Image for Review?')) {
        $.ajax({data: { campaign_id: campaign_id }, type: 'PUT', url: '/installation_images/' + image_id})
            .done(function () {
                //Update div class/count
                if($(image_div).hasClass('confirmed')) {
                    $(image_div).removeClass('confirmed');
                    confirm_count.text(parseInt(confirm_count.text()) - 1);
                    header_confirm_count.text(parseInt(header_confirm_count.text()) - 1);
                } else if ($(image_div).hasClass('client_viewable')) {
                    $(image_div).removeClass('client_viewable');
                    //This skips the process of giving a reason for accepting the image
                    $(image_div).addClass('reason_not_needed');
                    confirm_count.text(parseInt(confirm_count.text()) - 1);
                    header_confirm_count.text(parseInt(header_confirm_count.text()) - 1);
                    client_viewable_count.text(parseInt(client_viewable_count.text()) - 1);
                } else {
                    $(image_div).removeClass('rejected');
                    reject_count.text(parseInt(reject_count.text()) - 1);
                }
                $(image_div).addClass('open');
                $(image_div).addClass('reason_not_needed');
                open_count.text(parseInt(open_count.text()) + 1);
                reject_p.html('&nbsp'); //Clear reject reason
                //Remove highlights from other images, add to this one
                $('.installation-image').removeClass('highlight_image');
                $(image_div).addClass('highlight_image');
                if($('.tab.highlight').data('tab').includes('confirmed') || $('.tab.highlight').data('tab').includes('rejected') || $('.tab.highlight').data('tab').includes('client_viewable')) {
                    image_div.fadeOut(1500); //hide based on current tab
                }
                updateCircleColorWithStatusChange(image_id, "open")
            });
        // }
    }

    $('.undo').on('click', function(e) {
        e.preventDefault();
        undo_logic(this);
    });

    $('.delete').on('click', function(e) {
        e.preventDefault();
        const image_id = $(this).parent('.img_main').data('image_id');
        const campaign_id = $(this).parent('.img_main').data('campaign_id');
        const image_div = $('#image_' + image_id);
        const open_count = $('#open_img_count');
        const img_count = $('#img_count');

        const data = {
            confirm: 0,
            campaign_id: campaign_id
        };

        if(confirm('Permanently delete Image?')) {
            $.ajax({data: data, type: 'DELETE', url: '/installation_images/' + image_id})
                .done(function () {
                    image_div.remove();
                    open_count.text(parseInt(open_count.text()) - 1);
                    img_count.text(parseInt(img_count.text()) - 1);
                });
        }
    });

    var $image_checkboxes = $('.installation-image .thumbnail_checkbox');
    function syncImgSelectedHighlight() {
        $image_checkboxes.parent().toggleClass('img_selected', false);
        $image_checkboxes.filter(':checked').parent().toggleClass('img_selected', true);
    }

    function refreshImgSelectedCount() {
        var selected_img_count_val = $image_checkboxes.filter(':checked').length;
        $('#selected_img_count').text(selected_img_count_val);

        var image_action = $('#image_action_options').val();
        if (image_action == 'move_selected'){
            checkMoveImgSelectedCount(selected_img_count_val);
        }

    }

    function checkMoveImgSelectedCount(selected_img_count){
        if(selected_img_count === 5){
            $('.installation-image .thumbnail_checkbox').attr("disabled", true);
            $('.installation-image .thumbnail_checkbox:checked').attr("disabled", false);
        }else{
            $('.installation-image .thumbnail_checkbox').attr("disabled", false);
        }
    }

    var last_image_checked = null;
    $image_checkboxes.click(function(e) {
        $(this).parent().toggleClass('img_selected', $(this).checked);
        if (!last_image_checked) {
            last_image_checked = this;
            return;
        }

        if (e.shiftKey) {
            var $visible_image_checkboxes = $image_checkboxes.not(':hidden');

            var start = $visible_image_checkboxes.index(this);
            var end = $visible_image_checkboxes.index(last_image_checked);

            var shift_selected_checkboxes = $visible_image_checkboxes.slice(Math.min(start,end), Math.max(start,end)+ 1);
            shift_selected_checkboxes.prop('checked', last_image_checked.checked);
            syncImgSelectedHighlight();
        }

        refreshImgSelectedCount();

        last_image_checked = this;
    });

    $('#image_action_go').click(function(){
        var image_action = $('#image_action_options').val();
        if(image_action == 'download_all'){
            window.location.href = '/campaigns/' + $('.img_main').data('campaign_id') + '.csv';
        }else if(image_action == 'download_selected'){
            download_selected();
        }else if(image_action == 'archive_selected'){
            archive_selected();
        }else if (image_action == 'move_selected'){
            move_selected();
        }
    });

    function download_selected(){
        const campaign_id = $('.img_main').data('campaign_id');

        // param string of the image ids that should be included in the ZIP
        var image_ids = '';
        $('.installation-image .thumbnail_checkbox:checked').each(function() {
            if (image_ids.length > 0) {
                image_ids += '&';
            }
            image_ids += 'image_ids[]=' + $(this).val();
        });

        var selected_images = image_ids.split('&');

        if (selected_images.length > 1) {
            if(document.getElementById('download_processing').innerText != "Creating Zip File..."){
                document.getElementById('download_processing').style.color = "black";
                document.getElementById('download_processing').innerText = "Creating Zip File...";
                //document.getElementById('download_path').value = "";
                //document.getElementById('download_zip').innerText = "";
                //document.getElementById('zip_info').innerText = "";
                $.ajax({
                    url: '/campaigns/' + campaign_id + '/generate_zip.json',
                    type: "post",
                    data: image_ids,
                    success: function success(data) {
                        document.getElementById('download_processing').style.color = "green";
                        document.getElementById('download_processing').innerText = "Zip File Created";
                        document.getElementById('download_path').value = data['filepath'];
                        document.getElementById('download_zip').innerText = "Download Zip";
                        document.getElementById('zip_info').innerText = "(" + data['file_created_at'] + " - " + data['image_count'] + " photos)";
                    },
                    error: function error(data) {
                        //document.getElementById('images_zip_download').innerText = "Download Images";
                    }
                });
            } else {
                alert("A download of images is currently being generated");
            }
            // Do not download unless at least 1 image is selected
        } else if (image_ids.length > 0) {
            window.location.href = '/campaigns/' + campaign_id + '.zip?' + image_ids;
        }
    }

    function archive_selected(){
        var campaign_id = $('.img_main').data('campaign_id');
        var image_ids = [];
        $('.installation-image .thumbnail_checkbox:checked').each(function() {
            image_ids.push($(this).val());
        });
        var data = {
            update: 1,
            image_ids: image_ids
        };
        $.ajax({
            url: "/installation_images/update_archives",
            type: "put",
            data: data,
            success: function(data) {
                window.location.href = '/campaigns/' + campaign_id;
            },
            error: function(data) {

            }
        });
    }

    function move_selected(){
        var image_ids = [];
        $('.installation-image .thumbnail_checkbox:checked').each(function() {
            image_ids.push($(this).val());
        });

        if (image_ids.length === 0){
            alert("You must select at least one photo to move");
        }else {
            var pathname = window.location.pathname.split("/");
            window.location.href = '/installation_images/move_images?campaign_id=' + pathname[2] + '&image_ids=' + image_ids;
        }
    }

    $('.show_hide').on('click', function(e) {
        $('.thumbnail_checkbox').show();
        $('.hidden').show();
    });

    $('#download_zip').on('click', function(e) {
        e.preventDefault(); //necessary to stop page from scrolling to top
        const campaign_id = $(this).data('campaign_id');
        var file_path = document.getElementById('download_path').value;
        //document.getElementById('download_path').value = "";
        //document.getElementById('download_zip').innerText = "";
        //document.getElementById('zip_info').innerText = "";
        //document.getElementById('images_zip_download').innerText = "Download Images";
        window.location.href = '/campaigns/' + campaign_id + '/download_zip.zip?filepath=' + file_path;
    });

    $('#img_all_selected').on('click', function(e) {
        //var all_checked = this.checked;
        $image_checkboxes.prop('checked', false);
        if (this.checked) {
            $image_checkboxes.not(':hidden').prop('checked', true);
        }
        syncImgSelectedHighlight();
        refreshImgSelectedCount();
    });

    $('.thumbnail_checkbox').on('click', function(e) {

    });

    const PICTURE_ID_MAX = 1000000;

    $('button.upload_image').click(function(e) {
        var error_text = '';
        if ($('input:checkbox:checked').length === 0) {
            error_text = 'Please select one or more campaigns';
        } else if ($('#installation_image_image').val()==='') {
            error_text = 'Please upload an image';
        } else if ($('#installation_image_latitude').val().length <= 0) {
            error_text = 'Geodata not found - please ensure location is enabled and refresh browser';
        } else {
            //window.addEventListener('beforeunload', confirmLeavePage);
            var picture_id =  Math.floor(Math.random() * Math.floor(PICTURE_ID_MAX));
            var image_file_name = document.getElementById('image_file_name').innerText;
            $(".current_uploading_images" ).append( "<p id='" + picture_id  + "'>" + currentlyUploadingMessage(image_file_name, picture_id) + "</p>" );
            setUploadingMessage('Image Uploading - ' + picture_id);
            var fd = new FormData(document.getElementById('new_installation_image'));
            fd.append('picture_id', picture_id );
            $('#installation_image_image').val("");
            $('#image_file_name').text("");
            let uploadfd = new FormData();
            uploadfd.append('picture_id', picture_id);
            uploadfd.append('campaign_ids[]', fd.getAll("campaign_ids[]"));

            $.ajax({
                url: "/installation_images/log_upload",
                type: "post",
                data: uploadfd,
                contentType: false,
                processData: false,
                cache: false,
                success: function (data) {
                    fd.append('upload_log_id', data.upload_log_id);
                    installerUploadRequest(fd);
                },
                error: function (data, textStatus, errorThrown) {
                    updateUploadStatusErrorMessage(this.data.get('picture_id'), data.status, data.readyState, data.statusText);
                }
            });

            return;
        }
        e.preventDefault();
        setSubmitErrorMessage(error_text);

    });

    function installerUploadRequest(fd) {
        $.ajax({
            url: "/installation_images",
            type: "post",
            data: fd,
            retries: 5,
            contentType: false,
            processData: false,
            cache: false,
            success: function (data) {
                document.getElementById(data).remove();

                overwriteUploadCompleteMessage(data);
                //if ($(".current_uploading_images > p").length == 0) {
                //    window.removeEventListener('beforeunload', confirmLeavePage);
                //}
            },
            error: function (data, textStatus, errorThrown) {
                updateUploadStatusErrorMessage(this.data.get('picture_id'), data.status, data.readyState, data.statusText);

                if (data.readyState == 0) {
                    retryPhotoUpload(this);
                }
            }
        });
    }

    $('button.admin_upload_image').click(function(e) {
        var error_text = '';
        if ($('input:checkbox:checked').length === 0) {
            error_text = 'Please select one or more campaigns';
        } else if ($('#installation_image_image').val()==='') {
            error_text = 'Please upload an image';
        // } else if ($('#installation_image_latitude').val().length <= 0) {
        //     error_text = 'Geodata not found - please ensure location is enabled and refresh browser';
        } else {
            //window.addEventListener('beforeunload', confirmLeavePage);
            var picture_id =  Math.floor(Math.random() * Math.floor(PICTURE_ID_MAX));
            var image_file_name = document.getElementById('image_file_name').innerText;
            $(".current_uploading_images" ).append( "<p id='" + picture_id  + "'>" + currentlyUploadingMessage(image_file_name, picture_id) + "</p>" );
            setUploadingMessage('Image Uploading - ' + picture_id);
            var fd = new FormData(document.getElementById('new_installation_image'));
            fd.append('picture_id', picture_id );
            $('#installation_image_image').val("");
            $('#image_file_name').text("");
            let uploadfd = new FormData();
            uploadfd.append('picture_id', picture_id);
            uploadfd.append('campaign_ids[]', fd.getAll("campaign_ids[]"));

            $.ajax({
                url: "/installation_images/log_upload",
                type: "post",
                data: uploadfd,
                contentType: false,
                processData: false,
                cache: false,
                success: function (data) {
                    fd.append('upload_log_id', data.upload_log_id);
                    adminUploadRequest(fd);
                },
                error: function (data, textStatus, errorThrown) {
                    updateUploadStatusErrorMessage(this.data.get('picture_id'), data.status, data.readyState, data.statusText);
                }
            });

            return;
        }
        e.preventDefault();
        setSubmitErrorMessage(error_text);

    });

    function adminUploadRequest(fd) {
        $.ajax({
            url: "/installation_images/save_upload",
            type: "put",
            data: fd,
            retries: 5,
            contentType: false,
            processData: false,
            cache: false,
            success: function (data) {
                document.getElementById(data['id']).remove();

                // overwriteUploadCompleteMessage(data);
                //update map
                var bounds = map.getBounds();
                updateMap(map, bounds, data['lat'], data['lng'], data['ins_img_id'])
                map.fitBounds(bounds);
            },
            error: function (data, textStatus, errorThrown) {
                updateUploadStatusErrorMessage(this.data.get('picture_id'), data.status, data.readyState, data.statusText);

                if (data.readyState == 0) {
                    retryPhotoUpload(this);
                }
            }
        });
    }

    $('#installation_image_image').change(function() {
        //Update geolocation coords
        setGeoData(navigator.geolocation);
        //extract file name
        const path_to_file = $(this).val();
        const path_array = path_to_file.split('\\');
        $('#image_file_name').text(path_array[path_array.length-1]);
    });

    if($('#installation_image_latitude').length) {
        setGeoData(navigator.geolocation);
    }

    function setGeoData(geoLoc) {
        if (geoLoc) {
            geoLoc.getCurrentPosition((pos) => {
                $('#installation_image_latitude').val(pos.coords.latitude);
                $('#installation_image_longitude').val(pos.coords.longitude);
            });
        } else {
            alert('Geolocation not supported, please try a different browser.');
        }
    }

    function currentlyUploadingMessage(image_file_name, picture_id) {
        return image_file_name + ' - ' + picture_id;
    }

    function setUploadingMessage(message) {
        $('#uploading_message').text(message);
        setTimeout(function() {
            $('#uploading_message').html('&nbsp;');
        },3000);
    }

    function setSubmitErrorMessage(message) {
        var submit_error = $('#submit_error');
        submit_error.html(message);
        setTimeout(function() {
            submit_error.removeAttr('style');
            submit_error.html('');
        },3000);
    }

    function overwriteUploadCompleteMessage(image_num) {
        var message_element = $('<p></p>').text('Image - ' + image_num + ' Uploaded Successfully');
        $('#upload_complete_message').html(message_element);
    }

    function retryPhotoUpload(ajaxFunc) {
        if (ajaxFunc.retries > 0) {
            var seconds = 60;

            var image_num = ajaxFunc.data.get('picture_id');
            var image_file_name = ajaxFunc.data.get('installation_image[image]').name;
            var image_upload_status_tag = $('#' + image_num);
            image_upload_status_tag.text(currentlyUploadingMessage(image_file_name, image_num));
            image_upload_status_tag.addClass('alert');

            var image_upload_status_message = 'Upload Failed - Poor Connection. Please move to better connection.';
            image_upload_status_message += " Upload resending in <span id='" + image_num  + "_retry_timer'>" + seconds + '</span> seconds.';
            image_upload_status_message += ' Retries remaining: ' + ajaxFunc.retries;

            image_upload_status_tag.append(': ' + image_upload_status_message);

            ajaxFunc.retries--;

            var countdown = $('#' + image_num  + '_retry_timer');
            var interval = setInterval(function() {
                countdown.text(--seconds);

                if (seconds <= 0)
                {
                    image_upload_status_tag.text(currentlyUploadingMessage(image_file_name, image_num));
                    image_upload_status_tag.removeClass('alert');
                    clearInterval(interval);
                    $.ajax(ajaxFunc);
                }
            }, 1000);
        }
    }

    function updateUploadStatusErrorMessage(image_num, status, readyState, statusText) {
        var image_upload_status_tag = document.getElementById(image_num);
        document.getElementById(image_num).classList.add('alert');
        var image_upload_status_message;
        if (status == 401) {
            image_upload_status_message = 'Unauthorized - Please Refresh and Log In.';
            window.location.reload();
        } else {
            image_upload_status_message = 'Something went wrong while uploading the photo.';
        }
        image_upload_status_tag.append(': ' + image_upload_status_message + ' (' + readyState + ') ' + statusText);
        var timestamp = (new Date()).toLocaleTimeString([], {timeZoneName: 'short'});
        image_upload_status_tag.append(' (' + timestamp + ')');
    }

    function confirmLeavePage(event) {
        event.preventDefault();
        event.returnValue = '';
    }

    $('.upload_loss_confirm').click(function(e) {
        if ($(".current_uploading_images > p").length > 0) {
            if (confirm('If you leave the page now you will lose your currently uploading pictures. Are you sure?')) {
                // follow link as normal
            } else {
                e.preventDefault();
                return false;
            }
        }
    });

    $('#expand_data').on('click', function(e) {
        $('.campaign_info').toggle();
    });

    var all_markers = {};
    var all_circles = {};
    function initMap() {
        var map_element = document.getElementById("map");
        if(map_element) {
            var bounds = new google.maps.LatLngBounds();

            map = new google.maps.Map(map_element, {
                zoom: 14,
            });

            // Loop through our array of markers and circles & place each one on the map
            [all_markers, all_circles] = setAllMarkers(map, bounds);

            map.fitBounds(bounds);
        }

    }
    initMap();

    // Updates circle color on map based on status of image - Live update rather than on page load
    function updateCircleColorWithStatusChange(photoID, status) {
        var updatedCircleColor

        if (status === "confirmed") {
            updatedCircleColor = "green"
        } else if (status === "client_viewable") {
            updatedCircleColor = "orange"
        } else if (status === "open") {
            updatedCircleColor = "yellow"
        } else if (status === "rejected") {
            updatedCircleColor = "red"
        } else if (status === "accepted") {
            updatedCircleColor = "green"
        }

        all_circles[photoID].setOptions({ fillColor: updatedCircleColor, strokeColor: updatedCircleColor })
    }

    var row_image_column = $('.row.image_column');
    row_image_column.sortable({
        items: '.client_viewable',
        cursor: 'move',
        tolerance: 'pointer'
    });
    var clientViewableSortOrderArray = [];
    var sortOrderSaved;
    function sortOrderFunc(sortClientViewableImage) {
        let img_id = sortClientViewableImage.image_id;
        $.ajax({
            data: sortClientViewableImage, type: 'PUT', url: '/installation_images/' + img_id + '/update_sort_order',
            fail: function() {
                sortOrderSaved = false;
            }
        });
    }
    $('input#client_viewable_sort_order_save').on('click', function() {
        clientViewableSortOrderArray = $.map(row_image_column.find('.client_viewable > .img_main') , function(el, idx) {
            let image_id = $(el).data('image_id');
            let sort_order = $(el).data('sort_order');
            let id = $(el).data('campaign_image_id');
            let campaign_id = $(el).data('campaign_id');
            return {id: id, image_id: image_id, sort: sort_order, campaign_id: campaign_id, sort_order: idx + 1};
        });
        if(clientViewableSortOrderArray.length > 0) {
            sortOrderSaved = true;
            clientViewableSortOrderArray.forEach(object => sortOrderFunc(object));
            if(sortOrderSaved) {
                alert('Client Viewable Photos Sort Order Saved!');
            } else {
                alert('Error: Sort Order Not Saved!');
            }
        }
    });

    $('.rotate_right').on('click', function() {
        rotatePhoto('right', 90);
        display_rotate_save();

    });

    $('.rotate_left').on('click', function() {
        rotatePhoto('left', 90);
        display_rotate_save();
    });

    $('#rotate_save').on('click', function() {
        const rotate_data = {
            angle: $('#angle').val()
        };

        $.ajax({data: rotate_data, type: "post", url: '/installation_images/' + $('#image_id').val() + '/rotate_image',
            success: function (data) {
                // fd.append('upload_log_id', data.upload_log_id);
                // adminUploadRequest(fd);
            },
            error: function (data, textStatus, errorThrown) {
                // updateUploadStatusErrorMessage(this.data.get('picture_id'), data.status, data.readyState, data.statusText);
            }
        });
    });

    function rotatePhoto(direction, degress){
        var angle = direction === 'right' ? parseInt($('#angle').val()) + degress : parseInt($('#angle').val()) - degress ;
        if(angle == 360 || angle == -360) {
            angle = 0;
        }
        $('#angle').val(angle);
        $('#photo').css('transform','rotate(' + angle + 'deg)');
    }

    function display_rotate_save() {
        $('#rotate_save').css('display', 'inline');
    }



});
